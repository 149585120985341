* {
  box-sizing: border-box;
}

//----------------------------------------------------------------------------------------------------------------------------- Variables

$textColor: grey;

//----------------------------------------------------------------------------------------------------------------------------- Global
.App {
  // scroll-behavior: smooth;
  cursor: crosshair;
  width: 100vw;
  // height: 200vh;
  background-color: black; // rgb(34, 31, 31); // rgb(241, 233, 233); //

  font-family: Arial, Helvetica, sans-serif;
  text-align: center;

  display: grid;
  grid-template-rows: 100vh; // 110vh 100vh;
  grid-template-columns: 100vw;
}

//----------------------------------------------------------------------------------------------------------------------------- Elements

#cnv {
  grid-row: 1;
  z-index: 0;
}

.intro {
  display: grid;
  background-color: rgb(34, 31, 31); // rgb(231, 221, 221);
  h1 {
    width: 40vw;
    text-align: left;
    align-self: center;
    justify-self: center;
    color: grey;
  }
}
header {
  position: fixed;
  z-index: 1;
  width: min-content;
  height: fit-content;
  // background-color: rgba(240, 128, 128, 0.507);
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto;
  grid-template-areas: "logo menu";

  #logo {
    // background-color: lightcoral;
    // display: flex;

    width: fit-content;
    height: fit-content;
    grid-area: logo;
    text-align: left;
    padding: 3em;
    span.line {
      display: inline-block;
    }
    * {
      margin: unset;
      // letter-spacing: 0.1em;
      color: $textColor;
    }
    #name {
      letter-spacing: 0.1em;
      font-size: 1em;
      font-weight: bold;
      // line-height: 1em;
      cursor: text;
    }
    #slogan {
      letter-spacing: 0.1em !important;
      margin-top: 0.55em;
      font-size: 0.75em;
      font-weight: lighter;
      cursor: text;
    }
  }
}

#constr {
  position: fixed;
  align-self: flex-end;
  bottom: 6em;
  // top: 20em;
  left: 3em;
  text-align: left;
  font-weight: lighter;
  font-size: 1em;

  letter-spacing: 0.1em;
  z-index: 100;
  color: $textColor;
  p {
    cursor: text;
  }
  a {
    color: $textColor;
    font-size: 0.8em;
  }
}

@media screen and (min-width: 600px) {
  #cnv {
    z-index: 0;
  }
  header {
    #logo {
      padding: 5em;
    }
    #name {
      font-size: 1em !important;
    }
    #slogan {
      font-size: 0.8em !important;
    }
  }

  #constr {
    left: 5em;
  }
}

.videoCarousel {
  display: flex;
  overflow-x: scroll;
}
video {
  box-sizing: border-box;
  background-color: white;
  padding: 2em;
  width: 60%;
  height: 100vh;
}
